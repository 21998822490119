import { RSAA } from 'redux-api-middleware';
import { authenticate, get, logout, delegate } from 'utils/network';

export const AUTH_REQ = 'AUTH_REQ';
export const AUTH_SUCC = 'AUTH_SUCC';
export const AUTH_OUT = 'AUTH_OUT';
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const AUTH_FAIL = 'AUTH_FAIL';

export const USER_ME_REQ = 'USER_ME_REQ';
export const USER_ME_SUCC = 'USER_ME_SUCC';
export const USER_ME_FAIL = 'USER_ME_FAIL';

let timeoutID = null;

export const authLogin = credentials => {
  const request = authenticate(credentials);
  request.types = [AUTH_REQ, AUTH_SUCC, AUTH_FAIL];
  return { [RSAA]: request };
};

export const authLogout = () => {
  clearTimeout(timeoutID);
  const request = logout();
  request.types = ['NOOP', AUTH_OUT, '_FAIL'];
  return { [RSAA]: request };
};

export const authDelegate = () => dispatch => {
  const request = delegate();
  request.types = ['NOOP', AUTH_REFRESH, '_FAIL']
  return dispatch({ [RSAA]: request }).then(res => {
    if (!res.error) {
      const sec = ((res.payload.exp * 60) - 30) * 1000;
      timeoutID = setTimeout(() => {
        dispatch(authDelegate());
      }, sec);
    } else if (res.payload.status === 401) {
      // UNAUTHORIZED - stop refreshing of access token
      clearTimeout(timeoutID);
    } else {
      // SERVER is UNREACHABLE - retry every 60 seconds
      const sec = 60000;
      timeoutID = setTimeout(() => {
        dispatch(authDelegate());
      }, sec);
    }
  });
};

export const authCancelDelegate = () => {
  clearTimeout(timeoutID);
};

export const fetchMe = () => {
  const request = get('/me')
  request.types = [USER_ME_REQ, USER_ME_SUCC, USER_ME_FAIL];
  return { [RSAA]: request };
};

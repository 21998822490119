export const flushStorage = () => {
  localStorage.clear();
};

export const storageSetLogin = payload => {
  localStorage.setItem('user_id', payload.user_id);
  localStorage.setItem('access_token', payload.access_token);
  localStorage.setItem('refresh_token', payload.refresh_token);
  localStorage.setItem('remember', payload.remember);
  localStorage.setItem('exp', payload.exp);
};

export const storageSetMe = me => {
  localStorage.setItem('me', JSON.stringify(me));
};

export const storageSetSession = session => {
  localStorage.setItem('session', JSON.stringify(session));
}

import { fromJS } from 'immutable';
import {
  AUTH_REQ,
  AUTH_SUCC,
  AUTH_OUT,
  AUTH_FAIL,
  AUTH_REFRESH,
  USER_ME_REQ,
  USER_ME_SUCC,
  USER_ME_FAIL
} from 'actions/auth';
import { flushStorage, storageSetLogin, storageSetMe, storageSetSession } from 'utils/storage';

let initialState = fromJS({
  userID: 0,
  me: {},
  pending: false,
  error: '',
  logout: true
});

const isLoggedIn = Number(localStorage.getItem('user_id'));
if (isLoggedIn) {
  initialState = initialState
    .set('userID', isLoggedIn)
    .set('me', fromJS(JSON.parse(localStorage.getItem('me'))))
}

export default function authReducer(state = initialState, action) {
  if (action.type.match(/.*_FAIL$/)) {
    if (action.payload.status === 401) {
      try {
        flushStorage();
      } catch (e) {
        console.log(e);
      }
      return state
        .set('pending', false)
        .set('error', action.payload.response.error)
        .set('userID', 0)
        .set('me', fromJS({}));
    }
  }
  switch (action.type) {
    case AUTH_REQ:
    case USER_ME_REQ:
      return state
        .set('pending', true)
        .set('error', '');
    case AUTH_SUCC:
    case AUTH_REFRESH:
      try {
        storageSetLogin(action.payload);
      } catch (e) {
        console.log(e);
      }
      return state
        .set('pending', false)
        .set('error', '')
        .set('userID', action.payload.user_id)
        .set('logout', false);
    case AUTH_OUT:
      try {
        flushStorage();
      } catch (e) {
        console.log(e);
        return state;
      }
      return state
        .set('logout', true)
        .set('userID', 0)
        .set('me', fromJS({}));
    case USER_ME_SUCC:
      try {
        storageSetMe(action.payload.me);
        storageSetSession(action.payload.session);
      } catch (e) {
        console.log(e);
      }
      return state
        .set('pending', false)
        .set('me', fromJS(action.payload.me));
    case AUTH_FAIL:
    case USER_ME_FAIL:
      return state
        .set('pending', false)
        .set('error', action.payload.response ? action.payload.response.error : action.payload.message);
    default:
      return state;
  }
};

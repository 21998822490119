import React, { useEffect } from 'react';
import { authLogout } from 'actions/auth';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Logout = props => {
  const { _logout, _authLogout } = props;
  useEffect(() => {
    _authLogout();
  }, [_authLogout])
  return (
    <div>
      {
        _logout
          ? <Redirect to="/login" />
          : 'Logging out...'
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    _logout: state.auth.get('logout')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _authLogout: () => dispatch(authLogout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
import { RSAA } from "redux-api-middleware";
import { get } from "utils/network";

export const COURSE_REQ = 'COURSE_REQ';
export const COURSE_REQ_SUCC = 'COURSE_REQ_SUCC';
export const COURSE_REQ_FAIL = 'COURSE_REQ_FAIL';

export const fetchCourses = () => {
  const request = get('/courses');
  request.types = [COURSE_REQ, COURSE_REQ_SUCC, COURSE_REQ_FAIL];
  return { [RSAA]: request };
}
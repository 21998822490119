import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import MainApp from 'components/MainApp';
import ProtectedRoute from 'components/custom/ProtectedRoute';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <ProtectedRoute path="/" component={MainApp} />
      </Switch>
    </Router>
  );
}

export default App;

const { fromJS } = require("immutable");

const API = process.env.REACT_APP_API;
const AUTH = process.env.REACT_APP_AUTH;

const serialize = query => {
  if (typeof query !== 'object') {
    return query;
  }
  const pairs = [];
  Object.keys(query).forEach(key => {
    if (query[key]) {
      pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(query[key]))
    }
  });
  return pairs.join('&');
};

const request = () =>
  fromJS({
    headers: { 'Content-Type': 'application/json' }
  });

const apiRequest = () => {
  const req = request();
  return req.setIn(['headers', 'Authorization'], 'Bearer ' + localStorage.getItem('access_token'))
};

export const authenticate = credentials => {
  const req = {
    endpoint: AUTH + '/login',
    method: 'POST',
    body: JSON.stringify(credentials)
  };
  return request()
    .merge(fromJS(req))
    .toJS();
};

export const logout = () => {
  const body = {
    id: Number(localStorage.getItem('user_id')),
    refresh_token: localStorage.getItem('refresh_token'),
  };
  const req = {
    endpoint: AUTH + '/logout',
    method: 'POST',
    body: JSON.stringify(body),
  };
  return request()
    .merge(fromJS(req))
    .toJS();
};


export const delegate = () => {
  const session = JSON.parse(localStorage.getItem('session'));
  const body = {
    user_id: Number(localStorage.getItem('user_id')),
    semcamp_id: Number(session.semcamp_id),
    campus_id: Number(session.campus_id),
    refresh_token: localStorage.getItem('refresh_token'),
    remember: localStorage.getItem('remember') === 'true',
  };
  const req = {
    endpoint: AUTH + '/delegate',
    method: 'POST',
    body: JSON.stringify(body),
  };
  return request()
    .merge(fromJS(req))
    .toJS();
};

export const get = (endpoint, query) => {
  const que = serialize(query) || '';
  if (que) {
    endpoint = endpoint + '?' + que;
  }
  const req = {
    endpoint: API + endpoint,
    method: 'GET'
  };
  return apiRequest()
    .merge(fromJS(req))
    .toJS();
};

export const post = (endpoint, payload) => {
  const req = {
    endpoint: API + endpoint,
    method: 'POST',
    body: JSON.stringify(payload)
  };
  return apiRequest()
    .merge(fromJS(req))
    .toJS();
};

export const put = (endpoint, payload) => {
  const req = {
    endpoint: API + endpoint,
    method: 'PUT',
    body: JSON.stringify(payload)
  };
  return apiRequest()
    .merge(fromJS(req))
    .toJS();
};

export const destroy = (endpoint, payload) => {
  const req = {
    endpoint: API + endpoint,
    method: 'POST',
    body: JSON.stringify(payload)
  };
  return apiRequest()
    .merge(fromJS(req))
    .toJS();
}

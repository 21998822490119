import { fromJS } from 'immutable';
import {
  APPLICANT_REQ,
  APPLICANT_REQ_SUCCESS,
  APPLICANT_REQ_FAIL
} from 'actions/applicants';

let initialState = fromJS({
  fetching: false,
  applicant: {}
});

export default function applicantReducer(state = initialState, action) {
  switch (action.type) {
    case APPLICANT_REQ:
      return state.set('fetching', true);
    case APPLICANT_REQ_SUCCESS:
      return state
        .set('fetching', false)
        .set('applicant', fromJS(action.payload));
    case APPLICANT_REQ_FAIL:
      return state.set('fetching', false);
    default:
      return state;
  }
}
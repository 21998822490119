import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { fetchApplications, fetchFile, approveApplication, rejectApplication } from 'actions/applications';
import { fetchApplicant } from 'actions/applicants';
import { formatTime } from '../utils/time';
import Applicant from './Applicant';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ApplicantList = (props) => {

  const { course, _fetchingApplications, _applications, _fetchingApplicant, _applicant } = props;
  const { _fetchApplications, _fetchApplicant, _fetchFile, _approveApplication, _rejectApplication } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOpenRejectDialog, setRejectDialogOpen] = useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [reasonStr, setReasonStr] = useState('')
  const [alertOpt, setAlertOpt] = useState({ severity: 'info', 'message': '' });
  const classes = useStyles();

  let currentDate = new Date();
  currentDate.setSeconds(0);
  const [selectedDate, setSelectedDate] = React.useState(currentDate);
  const [selectedStart, setSelectedStart] = React.useState(currentDate);
  const [selectedEnd, setSelectedEnd] = React.useState(currentDate);

  const handleOpen = (application) => {
    if (_applicant.getIn(['applicant', 'id']) !== application.get('applicant_id')) {
      _fetchApplicant(application.get('applicant_id'));
    }
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleReasonChange=(e)=>{
    setReasonStr(e.target.value)
  }

  const handleCloseRDialog = () => {
    setRejectDialogOpen(false)
  }

  const handleApprove = () => {
    const application = _applicant.get('application')
      .set('exam_date', selectedDate.toLocaleDateString())
      .set('start_at', selectedStart.toLocaleTimeString())
      .set('end_at', selectedEnd.toLocaleTimeString());
    _approveApplication(application).then(res => {
      if (!res.error) {
        setAlertOpt({ severity: 'success', message: 'Application Approved!' });
        setIsSnackbarOpen(true);
        setIsDialogOpen(false);
        _fetchApplications(course.get('id'));
      } else {
        setAlertOpt({ severity: 'error', message: `ERROR: ${res.payload.response.error}` });
        setIsSnackbarOpen(true);
      }
    });
  }

  const handleReject = () => {
    setReasonStr('')
    setRejectDialogOpen(true)
  }

  const onReject = () => {
    const application = _applicant.get('application')
     .set('last_name',_applicant.get('applicant').get('last_name'))
     .set('first_name',_applicant.get('applicant').get('first_name'))
     .set('middle_name',_applicant.get('applicant').get('middle_name'))
     .set('suffix_name',_applicant.get('applicant').get('suffix_name'))
     .set('sex',_applicant.get('applicant').get('sex'))
     .set('dob',_applicant.get('applicant').get('dob_raw'))
     .set('email',_applicant.get('applicant').get('email'))
     .set('contact_no',_applicant.get('applicant').get('contact_no'))
     .set('remarks',reasonStr)
    _rejectApplication(application).then(res => {
      if (!res.error) {
        setAlertOpt({ severity: 'success', message: 'Application Rejected!' });
        setIsSnackbarOpen(true);
        setRejectDialogOpen(false);
        handleClose()
        _fetchApplications(course.get('id'));
      } else {
        setAlertOpt({ severity: 'error', message: `ERROR: ${res.payload.response.error}` });
        setIsSnackbarOpen(true);
      }
    });
  }

  const handleDateChange = (date) => {
    // exam date
    let month = date.getMonth();
    let day = date.getDate();
    let year = date.getFullYear();
    let newDate = new Date(year, month, day);
    setSelectedDate(newDate);
    // start time
    let hrs = selectedStart.getHours();
    let mins = selectedStart.getMinutes();
    newDate.setHours(hrs);
    newDate.setMinutes(mins);
    setSelectedStart(newDate);
    // end time
    hrs = selectedEnd.getHours();
    mins = selectedEnd.getMinutes();
    newDate.setHours(hrs);
    newDate.setMinutes(mins);
    setSelectedEnd(newDate);
  };

  const handleStartChange = (date) => {
    // exam date
    let month = selectedDate.getMonth();
    let day = selectedDate.getDate();
    let year = selectedDate.getFullYear();
    let newDate = new Date(year, month, day);
    // start time
    let hrs = date.getHours();
    let mins = date.getMinutes();
    newDate.setHours(hrs);
    newDate.setMinutes(mins);
    setSelectedStart(newDate);
  }

  const handleEndChange = (date) => {
    // exam date
    let month = selectedDate.getMonth();
    let day = selectedDate.getDate();
    let year = selectedDate.getFullYear();
    let newDate = new Date(year, month, day);
    // end time
    let hrs = date.getHours();
    let mins = date.getMinutes();
    newDate.setHours(hrs);
    newDate.setMinutes(mins);
    setSelectedEnd(newDate);
  }

  useEffect(() => {
    if (course.size) {
      _fetchApplications(course.get('id'));
    }
  }, [course]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {
        !_fetchingApplications ? (
          <TableContainer>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="center">APPLICATION ID</TableCell>
                  <TableCell align="center">TYPE</TableCell>
                  <TableCell align="center">STATUS</TableCell>
                  <TableCell align="center">APPLIED ON</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_applications.map((application, index) => (
                  <TableRow
                    hover
                    key={application.get('id')}
                    onClick={() => handleOpen(application)}
                  >
                    <TableCell component="th" scope="row">{index + 1}.</TableCell>
                    <TableCell align="center">{application.get('id')}</TableCell>
                    <TableCell align="center">{application.get('type')}</TableCell>
                    <TableCell align="center">{application.get('status')}</TableCell>
                    <TableCell align="center">{formatTime(application.get('applied_on'))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <LinearProgress />
        )
      }
      <Dialog
        fullScreen
        open={isDialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Application Details
            </Typography>
            <Button autoFocus variant="contained" color="secondary" onClick={handleReject}>
              Reject
            </Button>
            <Button autoFocus variant="contained" color="primary" onClick={handleApprove}>
              Approve
            </Button>
          </Toolbar>
        </AppBar>
        <Applicant
          fetching={_fetchingApplicant}
          details={_applicant}
          getFile={_fetchFile}
          selectedDate={selectedDate}
          selectedStart={selectedStart}
          selectedEnd={selectedEnd}
          handleDateChange={handleDateChange}
          handleStartChange={handleStartChange}
          handleEndChange={handleEndChange}
        />
      </Dialog>

      <Dialog
            open={isOpenRejectDialog}
            onClose={handleCloseRDialog}
            maxWidth="lg"
            aria-labelledby="max-width-dialog-title">
        <DialogTitle id="form-dialog-title">Reject Application</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please indicate the reason below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reason"
            type="text"
            value={reasonStr}
            onChange={handleReasonChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRDialog} variant="contained" color="primary">
            Cancel
          </Button>
          <Button onClick={onReject} variant="contained" color="secondary">
            Reject
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={() => setIsSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={() => setIsSnackbarOpen(false)} severity={alertOpt.severity} variant="filled">
          {alertOpt.message}
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = state => {
  return {
    _fetchingApplications: state.applications.get('fetching'),
    _applications: state.applications.get('applicants'),
    _fetchingApplicant: state.applicants.get('fetching'),
    _applicant: state.applicants.get('applicant')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    _fetchApplications: (id) => dispatch(fetchApplications(id)),
    _fetchApplicant: (id) => dispatch(fetchApplicant(id)),
    _fetchFile: (fileID) => dispatch(fetchFile(fileID)),
    _approveApplication: (payload) => dispatch(approveApplication(payload)),
    _rejectApplication: (payload) => dispatch(rejectApplication(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantList);

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, _isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (_isLoggedIn) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
      }}
    />
  );
};

const mapStateToProps = state => {
  return {
    _isLoggedIn:
      state.auth.get('userID') &&
      state.auth.get('me')
  };
};

export default connect(mapStateToProps)(ProtectedRoute);

import { fromJS } from 'immutable';
import {
  SCHEDULE_ASSIGN_REQ,
  SCHEDULE_ASSIGN_REQ_SUCCESS,
  SCHEDULE_ASSIGN_REQ_FAIL
} from 'actions/schedules';

let initialState = fromJS({
  fetching: false,
  schedule: {}
});

export default function scheduleReducer(state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_ASSIGN_REQ:
      return state.set('fetching', true);
    case SCHEDULE_ASSIGN_REQ_SUCCESS:
      return state
        .set('fetching', false)
        .set('schedule', fromJS(action.payload));
    case SCHEDULE_ASSIGN_REQ_FAIL:
      return state.set('fetching', false);
    default:
      return state;
  }
}

import { fromJS } from 'immutable';
import {
  APPLICATIONS_REQ,
  APPLICATIONS_REQ_SUCCESS,
  APPLICATIONS_REQ_FAIL
} from 'actions/applications';

let initialState = fromJS({
  fetching: false,
  applicants: []
});

export default function applicationsReducer(state = initialState, action) {
  switch (action.type) {
    case APPLICATIONS_REQ:
      return state.set('fetching', true);
    case APPLICATIONS_REQ_SUCCESS:
      return state
        .set('fetching', false)
        .set('applicants', fromJS(action.payload));
    case APPLICATIONS_REQ_FAIL:
      return state.set('fetching', false);
    default:
      return state;
  }
}
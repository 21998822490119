import { RSAA } from 'redux-api-middleware';
import { post } from 'utils/network';

export const SCHEDULE_ASSIGN_REQ = 'SCHEDULE_ASSIGN_REQ';
export const SCHEDULE_ASSIGN_REQ_SUCCESS = 'SCHEDULE_ASSIGN_REQ_SUCCESS';
export const SCHEDULE_ASSIGN_REQ_FAIL = 'SCHEDULE_ASSIGN_REQ_FAIL';

export const assignSchedule = (payload) => {
  const request = post('/schedule', payload);
  request.types = [SCHEDULE_ASSIGN_REQ, SCHEDULE_ASSIGN_REQ_SUCCESS, SCHEDULE_ASSIGN_REQ_FAIL];
  return { [RSAA]: request };
}
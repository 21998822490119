import { RSAA } from "redux-api-middleware";
import { get } from "utils/network";

export const APPLICANT_REQ = 'APPLICANT_REQ';
export const APPLICANT_REQ_SUCCESS = 'APPLICANT_REQ_SUCCESS';
export const APPLICANT_REQ_FAIL = 'APPLICANT_REQ_FAIL';

export const fetchApplicant = (applicantID) => {
  const request = get('/applicant/' + applicantID);
  request.types = [APPLICANT_REQ, APPLICANT_REQ_SUCCESS, APPLICANT_REQ_FAIL];
  return { [RSAA]: request };
}
export const formatTime = timestampString => {
  let timestamp = new Date(Date.parse(timestampString));
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  return new Intl.DateTimeFormat('en-PH', options).format(timestamp);;
}

export const formatDate = timestampString => {
  let timestamp = new Date(Date.parse(timestampString));
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }
  return new Intl.DateTimeFormat('en-PH', options).format(timestamp);;
}
import { fromJS } from 'immutable';
import {
  COURSE_REQ,
  COURSE_REQ_SUCC,
  COURSE_REQ_FAIL
} from 'actions/courses';

let initialState = fromJS({
  fetching: false,
  list: []
});

export default function coursesReducer(state = initialState, action) {
  switch (action.type) {
    case COURSE_REQ:
      return state.set('fetching', true);
    case COURSE_REQ_SUCC:
      return state
        .set('fetching', false)
        .set('list', fromJS(action.payload));
    case COURSE_REQ_FAIL:
      return state.set('fetching', false);
    default:
      return state;
  }
}
import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFormik } from 'formik';
import { authLogin, fetchMe } from 'actions/auth';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const { _error, _pending } = props;
  const loginBtn = _pending ? 'Signing In ...' : 'Sign In';

  const validate = payload => {
    const errors = {};
    if (!payload.username) {
      errors.username = 'required';
    }
    if (!payload.password) {
      errors.password = 'required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: false
    },
    validate: validate,
    onSubmit: payload => {
      props._authLogin(payload).then(res => {
        if (!res.error) {
          props._fetchMe().then(res1 => {
            if (!res1.error) {
              console.log(props.history);
              props.history.replace('/');
            }
          });
        }
      });
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={formik.handleSubmit} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id="remember"
                checked={formik.values.remember}
                onChange={formik.handleChange}
              />
            }
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={_pending}
          >
            {loginBtn}
          </Button>
        </form>
        {
          !!_error && (<FormHelperText style={{ color: '#c34a36' }}>
            {_error}
          </FormHelperText>)
        }
      </div>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    _error: state.auth.get('error'),
    _pending: state.auth.get('pending')
  };
}

const mapDispatchToProps = dispatch => {
  return {
    _authLogin: payload => dispatch(authLogin(payload)),
    _fetchMe: () => dispatch(fetchMe())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

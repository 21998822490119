import React, { useState, useRef, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { formatDate } from '../utils/time';
import { getIncomeBracketVal } from '../utils/income';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: 200,
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`
    },
    [theme.breakpoints.up("sm")]: {
      height: `calc(100vh - 64px)`
    }
  },
  title: {
    marginTop: 20,
    marginBottom: 10
  }
}));

const Applicant = (props) => {
  const { fetching, details, getFile } = props;
  const { selectedDate, selectedStart, selectedEnd, handleDateChange, handleStartChange, handleEndChange } = props;
  const [fileURLs, setFileURLs] = useState([]);
  const fu = useRef([]); // for cleanup
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [alertOpt, setAlertOpt] = useState({ severity: 'info', 'message': '' });
  const classes = useStyles();
  const applicant = details.get('applicant', undefined);
  const application = details.get('application', undefined);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      fu.current.forEach(url => {
        URL.revokeObjectURL(url);
      })
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!fetching && application) {
      const credentials = application.get('credentials', undefined);
      if (credentials) {
        credentials.forEach((item, n) => {
          if (n === 0) {
            setFetchingFiles(true);
            setAlertOpt({ severity: 'info', message: 'Fetching Requirements...' });
            setIsSnackbarOpen(true);
          }
          getFile(item).then(res => {
            if (!res.error) {
              setFileURLs(prev => {
                return [...prev, res.payload];
              });
              fu.current.push(res.payload);
            } else {
              setFetchingFiles(false);
              setAlertOpt({ severity: 'error', message: `ERROR: ${res.payload.response.error}` });
              setIsSnackbarOpen(true);
            }
          });
        });
      }
    }
  }, [fetching, application]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (application) {
      const credentials = application.get('credentials', undefined);
      if (credentials) {
        if (credentials.size === fileURLs.length) {
          setFetchingFiles(false);
          setAlertOpt({ severity: 'success', message: 'Done Fetching Requirements.' });
          setIsSnackbarOpen(true);
        }
      }
    }
  }, [fileURLs, application]);

  if (fetching) {
    return <LinearProgress />
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField label="Campus" value={application.get('campus', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField label="Application Type" value={application.get('type', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Course Applied" value={application.get('course', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Second Priority Course" value={application.get('course2', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Third Priority Course" value={application.get('course3', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
        </Grid>
        <Typography color="primary" variant="h6" className={classes.title}>
          Personal Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField label="Last Name" value={applicant.get('last_name', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField label="First Name" value={applicant.get('first_name', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField label="Middle Name" value={applicant.get('middle_name', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField label="Suffix Name" value={applicant.get('suffix_name', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField label="Gender" value={applicant.get('sex', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField label="Civil Status" value={applicant.get('civil_status', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField label="Citizenship" value={applicant.get('citizenship', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField label="Date of Birth" value={applicant.get('dob_raw') === '' ? '-' : formatDate(applicant.get('dob_raw'))} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <TextField label="Place of Birth" value={applicant.get('pob', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="House No./Street/Brgy." value={applicant.get('house_no_street_brgy', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField label="Municipality/Town" value={applicant.get('area', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField label="Province" value={applicant.get('province', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Alternate Address" value={applicant.get('alt_address', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField label="Contact No." value={applicant.get('contact_no', '-')} placeholder="09123456789" fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <TextField label="Email" value={applicant.get('email', '-')} placeholder="example@email.com" fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Name of Father" value={applicant.get('name_father', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Name of Mother" value={applicant.get('name_mother', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Parent's Address" value={applicant.get('address_parents', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Income Bracket" value={getIncomeBracketVal(applicant.get('income_status', '-'))} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Do you identify yourself as a Person with Disability (PWD) or other has a chronic condition?" value={applicant.get('is_pwd', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Do you identify yourself as a member of indegenous people?" value={applicant.get('is_ip', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
        </Grid>
        <Typography color="primary" variant="h6" className={classes.title}>
          Academic Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField label="Track Strand" value={applicant.get('track_strand', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="LRN" value={applicant.getIn(['lrn_raw', 'Valid']) ? applicant.getIn(['lrn_raw', 'String']) : '-'} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="General Average" value={applicant.get('general_average', 0)} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Last School Attended" value={applicant.get('last_school_attended', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="School Address" value={applicant.get('last_school_attended_address', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="Date Graduated" value={applicant.getIn(['date_graduated_raw', 'Valid']) ? formatDate(applicant.getIn(['date_graduated_raw', 'Time'])) : "-"} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField label="Honor's Received" value={applicant.get('honors_received', '-')} fullWidth inputProps={{ readOnly: true }} />
          </Grid>
        </Grid>
        <Typography color="primary" variant="h6" className={classes.title}>
          Requirements
        </Typography>
        {
          fetchingFiles ? (
            <LinearProgress />
          ) : (
            <List>
              {
                fileURLs.map((item, n) => {
                  return (
                    <ListItem
                      key={n}
                      button
                      component="a"
                      target="_blank"
                      rel="noreferrer"
                      href={item}
                      divider
                    >
                      <ListItemText
                        primary={`FILE NO. ${n + 1}`}
                        secondary={item}
                        secondaryTypographyProps={{ variant: 'caption' }}
                      />
                    </ListItem>);
                })
              }
            </List>
          )
        }
{/*        <Typography color="primary" variant="h6" className={classes.title}>
          Assign Schedule
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker"
              label="Exam Date"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="start-time-picker"
              label="Start Time"
              value={selectedStart}
              onChange={handleStartChange}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="end-time-picker"
              label="End Time"
              value={selectedEnd}
              onChange={handleEndChange}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        */}
      </Container>
      <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={() => setIsSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={() => setIsSnackbarOpen(false)} severity={alertOpt.severity} variant="filled">
          {alertOpt.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Applicant;

import { RSAA } from 'redux-api-middleware';
import { get, put, destroy } from 'utils/network';

export const APPLICATIONS_REQ = 'APPLICATIONS_REQ';
export const APPLICATIONS_REQ_SUCCESS = 'APPLICATIONS_REQ_SUCCESS';
export const APPLICATIONS_REQ_FAIL = 'APPLICATIONS_REQ_FAIL';
export const CREDENTIAL_REQ = 'CREDENTIAL_REQ';
export const CREDENTIAL_REQ_SUCCESS = 'CREDENTIAL_REQ_SUCCESS';
export const CREDENTIAL_REQ_FAIL = 'CREDENTIAL_REQ_FAIL';
export const APPROVE_APPLICATION_REQ = 'APPROVE_APPLICATION_REQ';
export const APPROVE_APPLICATION_REQ_SUCCESS = 'APPROVE_APPLICATION_REQ_SUCCESS';
export const APPROVE_APPLICATION_REQ_FAIL = 'APPROVE_APPLICATION_REQ_FAIL';

export const fetchApplications = (scCourseID) => {
  const request = get('/applications/' + scCourseID);
  request.types = [APPLICATIONS_REQ, APPLICATIONS_REQ_SUCCESS, APPLICATIONS_REQ_FAIL];
  return { [RSAA]: request };
}

export const fetchFile = (fileID) => {
  const request = get('/applications/credential/' + fileID);
  request.types = [CREDENTIAL_REQ, {
    type: CREDENTIAL_REQ_SUCCESS,
    payload: async (action, state, response) => {
      const blob = await response.blob();
      return await URL.createObjectURL(blob);
    }
  }, CREDENTIAL_REQ_FAIL];
  return { [RSAA]: request };
}

export const approveApplication = (payload) => {
  const request = put('/application/approve/' + payload.get('id'), payload);
  request.types = [APPROVE_APPLICATION_REQ, APPROVE_APPLICATION_REQ_SUCCESS, APPROVE_APPLICATION_REQ_FAIL];
  return { [RSAA]: request };
}

export const rejectApplication = (payload) => {
  const request = destroy('/application/reject/' + payload.get('id'), payload);
  request.types = [APPROVE_APPLICATION_REQ, APPROVE_APPLICATION_REQ_SUCCESS, APPROVE_APPLICATION_REQ_FAIL];
  return { [RSAA]: request };
}

import { combineReducers } from 'redux';
import auth from './auth';
import courses from './courses';
import applications from './applications';
import applicants from './applicants';
import schedules from './schedules';

export default combineReducers({
  auth,
  courses,
  applications,
  applicants,
  schedules
});

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Toolbar, List, ListItem, ListItemText, Drawer, LinearProgress } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { authDelegate } from 'actions/auth';
import { fetchCourses } from 'actions/courses';
import Appbar from './Appbar';
import ApplicantList from './ApplicantList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: 300,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 300,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[300],
  }
}));

const MainApp = (props) => {
  const classes = useStyles();
  const { _fetching, _courses } = props;
  const { _authDelegate, _fetchCourses } = props;
  const [selectedCourse, setSelectedCourse] = useState({});

  useEffect(() => {
    _fetchCourses();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _authDelegate();
  }, [_authDelegate]);

  return (
    <div className={classes.root}>
      <Appbar firstName={props._me} />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List component="nav" aria-label="courses" style={{ padding: 0 }}>
            {
              _fetching ? (
                <div>
                  <LinearProgress />
                </div>
              ) : (
                _courses.size && _courses.map((course, index) => {
                  let isSelected = selectedCourse.size && course.get('id') === selectedCourse.get('id');
                  return (
                    <ListItem
                      key={index}
                      button
                      dense
                      divider
                      selected={isSelected}
                      onClick={() => setSelectedCourse(course)}
                    >
                      <ListItemText
                        primary={course.get('course_code')}
                        secondary={course.get('course_name')}
                        secondaryTypographyProps={{ variant: 'caption' }}
                      />
                    </ListItem>
                  )
                })
              )
            }
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <ApplicantList course={selectedCourse} />
      </main>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    _fetching: state.courses.get('fetching'),
    _courses: state.courses.get('list'),
    _me: state.auth.getIn(['me', 'first_name'])
  };
}

const mapDispatchToProps = dispatch => {
  return {
    _authDelegate: () => dispatch(authDelegate()),
    _fetchCourses: () => dispatch(fetchCourses())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);
